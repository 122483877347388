<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import {
    keyMaster,
    masterComputed,
    masterMethods,
    clientMethods,
    checkPermission,
    isValidDate,
    campaignMethods,
    prizeMethods,
    authComputed
} from '@/state/helpers';
import { maxLength, required } from 'vuelidate/lib/validators';
import { regexNumber } from '@/utils/format';
import { showMessage } from '@/utils/messages';
import DatePicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import MultiSelectCommon from '@/components/Select/multi/main.vue';

/**
 * Advanced-form component
 */
export default {
    page: {
        title: '賞品戻り新規登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        DatePicker,
        Multiselect,
        Footer,
        MultiSelectCommon
    },
    data() {
        return {
            loading: false,
            id: this.$route.query.id,
            listDataMasterCampaign: [],
            listDataMasterPrize: [],
            form: {
                return_date_y: '',
                return_date_m: '',
                return_date_d: '',
                return_date: '',
                reconfirmation_schedule_y: '',
                reconfirmation_schedule_m: '',
                reconfirmation_schedule_d: '',
                reconfirmation_schedule: '',
                prize: {
                    id: ''
                },
                end_flag: true,
                number: 1,
                province: '',
                shipping_company: '',
                contact_information_number: '',
                reason: '',
                return_status: {
                    id: ''
                },
                instruction: '',
                compatiable_with_jp_logistic_development: '',
                storage_code: '',
                note: ''
            },
            listDataMasterPartner: [],
            listDataMasterCompare: [],
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            customFormatDate: 'YYYY-MM-DD'
        };
    },
    watch: {
        'form.return_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.form.return_date_y = date[0];
                this.form.return_date_m = date[1];
                this.form.return_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'form.return_date_m': function () {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            this.changeDate();
            // Thực hiện thao tác khác tại đây
        },
        'form.return_date_d': function () {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            this.changeDate();
            // Thực hiện thao tác khác tại đây
        },
        'form.return_date_y': function () {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            this.changeDate();
            // Thực hiện thao tác khác tại đây
        },
        'form.reconfirmation_schedule': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.form.reconfirmation_schedule_y = date[0];
                this.form.reconfirmation_schedule_m = date[1];
                this.form.reconfirmation_schedule_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        }
    },
    computed: {
        ...masterComputed,
        ...authComputed
    },
    validations: {
        form: {
            return_date: {
                required,
                validate() {
                    if (this.form.return_date_m || this.form.return_date_d || this.form.return_date_y) {
                        return isValidDate(this.form.return_date_d + '/' + this.form.return_date_m + '/' + this.form.return_date_y);
                    }
                    return true;
                }
            },
            province: { maxLength: maxLength(255) },
            shipping_company: { required, maxLength: maxLength(255) },
            campaign: {
                id: { required }
            },
            return_status: {
                id: { required }
            },
            prize: {
                id: { required }
            },
            reconfirmation_schedule: {
                required,
                validate() {
                    if (this.form.reconfirmation_schedule_m || this.form.reconfirmation_schedule_d || this.form.reconfirmation_schedule_y) {
                        return isValidDate(
                            this.form.reconfirmation_schedule_d +
                                '/' +
                                this.form.reconfirmation_schedule_m +
                                '/' +
                                this.form.reconfirmation_schedule_y
                        );
                    }
                    return true;
                }
            },
            contact_information_number: { maxLength: maxLength(255) },
            reason: { required, maxLength: maxLength(255) },
            instruction: { maxLength: maxLength(255) },
            compatiable_with_jp_logistic_development: { maxLength: maxLength(255) },
            storage_code: { maxLength: maxLength(255) },
            note: { maxLength: maxLength(255) }
        }
    },

    mounted() {
        this.getFormattedDate();
        this.getListMasterCampaign();
        this.getListMasterCompare();
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...clientMethods,
        ...campaignMethods,
        ...prizeMethods,
        changeDate(key) {
            if (!this.form[key + '_m'] && !this.form[key + '_y'] && !this.form[key + '_d']) {
                this.form[key] = '';
            } else {
                this.form[key] = this.form[key + '_y'] + '-' + this.form[key + '_m'] + '-' + this.form[key + '_d'];
            }
        },
        getFormattedDate() {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');

            this.form.return_date = `${year}-${month}-${day}`;
        },
        getListMasterCampaign() {
            this.listMaster('all/campaigns').then((data) => {
                this.listDataMasterCampaign = data;
                if (this.currentUser.default_campaign) {
                    let index = this.listDataMasterCampaign.findIndex((x) => {
                        return x.id == this.currentUser.default_campaign.id;
                    });
                    if (index >= 0) {
                        this.form.campaign = this.listDataMasterCampaign[index];
                        this.getListMasterPrize();
                    }
                }
            });
        },
        getListMasterPrize() {
            if (this.form.campaign && this.form.campaign.id) {
                let query = [`limit=999`, `sorts[id]=desc`, `has[campaign]=${this.form.campaign.id}`];
                this.listPrize(query.join('&')).then((data) => {
                    this.listDataMasterPrize = data.data;
                });
            } else {
                this.listDataMasterPrize = [];
            }
        },
        resetForm() {
            this.form = {
                campaign: {
                    id: ''
                },
                prize: {
                    id: ''
                },
                end_flag: true,
                number: 1,
                return_date: '',
                province: '',
                shipping_company: '',
                contact_information_number: '',
                reason: '',
                return_status: {
                    id: ''
                },
                reconfirmation_schedule: '',
                instruction: '',
                compatiable_with_jp_logistic_development: '',
                storage_code: '',
                note: ''
            };
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        getListMasterCompare() {
            this.listMaster(keyMaster['prize.prize_less_status']).then((data) => {
                this.listDataMasterCompare = data;
            });
        },
        getDetail(id) {
            this.loading = true;
            this.detailPartners(id).then((data) => {
                this.form = data;
                this.loading = false;
            });
        },
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            } else {
                this.register();
            }
        },
        register() {
            if (this.form.prize && this.form.prize.id) {
                let dataObject = Object.assign(this.form);
                if (this.$route.query.id) {
                    this.updatePrizeReturn(dataObject).then((data) => {
                        if (data.code !== 200) {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                    });
                } else {
                    this.registerPrizeReturn([dataObject]).then((data) => {
                        if (data.code == 200) {
                            this.$router.push({ path: '/prize-return/list' }).then(() => {
                                showMessage(data.code, this.$bvToast, '賞品戻りが登録されました。');
                            });
                        }
                    });
                }
            }
        },
        goToEdit(path, id) {
            this.$router.push({ path: path, query: { id: id } });
        },

        inputCode() {
            this.form.postal_code = regexNumber(this.form.postal_code);
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="card form--sroll mb-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-10">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div>
                                                <label for=""><small class="text-gray">キャンペーン情報</small></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-10">
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05"
                                                    >キャンペーン名<span class="text-danger">*</span></label
                                                >
                                                <div class="col-sm-9">
                                                    <div>
                                                        <MultiSelectCommon
                                                            :id="`select-campaign-object`"
                                                            :value.sync="form.campaign"
                                                            :options="listDataMasterCampaign"
                                                            :config="{
                                                                error: submitted && $v.form.campaign.$error,
                                                                isRemove: true,
                                                                trackBy: 'id',
                                                                label: 'name'
                                                            }"
                                                            @close="getListMasterPrize"
                                                            @select="
                                                                () => {
                                                                    form.prize = null;
                                                                }
                                                            "
                                                        />

                                                        <div v-if="submitted && $v.form.campaign.$error" class="invalid-feedback">
                                                            <span v-if="!$v.form.campaign.required">
                                                                {{ $t('validateField.requiredSelect', { field: 'エンドクライアント名' }) }}</span
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div>
                                                <label for=""><small class="text-gray">賞品情報</small></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-10">
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">賞品名<span class="text-danger">*</span></label>
                                                <div class="col-sm-9">
                                                    <div
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.prize.id.$error
                                                        }"
                                                    >
                                                        <multiselect
                                                            :showPointer="false"
                                                            @open="getListMasterPrize"
                                                            :showNoResults="false"
                                                            selectedLabel=""
                                                            deselectLabel=""
                                                            placeholder=""
                                                            selectLabel=""
                                                            :showNoOptions="false"
                                                            track-by="id"
                                                            label="name"
                                                            v-model="form.prize"
                                                            :options="listDataMasterPrize"
                                                        ></multiselect>
                                                        <i
                                                            v-if="form.prize && form.prize.id"
                                                            @click="
                                                                $event.stopPropagation();
                                                                form.prize = {};
                                                            "
                                                            style="
                                                                cursor: pointer;
                                                                position: absolute;
                                                                right: 23px;
                                                                z-index: 9;
                                                                top: 11px;
                                                                background: #fff;
                                                            "
                                                            class="fa fa-window-close"
                                                        ></i>
                                                    </div>
                                                    <div v-if="submitted && $v.form.prize.id.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.prize.id.validate">賞品名を選択してください。</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div>
                                                <label for=""><small class="text-gray">運送会社情報</small></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-10">
                                            <div
                                                class="form-group row"
                                                v-if="$route.query.id && form.campaign && form.campaign.partners && form.campaign.partners.length > 0"
                                            >
                                                <label class="col-sm-3" for="validationCustom05"></label>
                                                <div class="col-sm-9">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>対応パートナー</th>
                                                                <th>パートナー担当</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in form.campaign.partners" :key="item.id">
                                                                <td>{{ item.name }}</td>
                                                                <td>{{ item.partner_pic_name }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">戻り日<span class="text-danger">*</span></label>
                                                <div class="col-sm-8">
                                                    <div class="d-flex align-items-center">
                                                        <input
                                                            type="text"
                                                            v-model="form.return_date_y"
                                                            style="width: 80px; padding-right: 0 !important"
                                                            class="form-control mr-2"
                                                            :class="{
                                                                'is-invalid': submitted && $v.form.return_date.$error
                                                            }"
                                                        />
                                                        年
                                                        <input
                                                            type="text"
                                                            v-model="form.return_date_m"
                                                            style="width: 47px; padding-right: 0 !important"
                                                            class="form-control mx-2"
                                                            :class="{
                                                                'is-invalid': submitted && $v.form.return_date.$error
                                                            }"
                                                        />
                                                        月
                                                        <input
                                                            type="text"
                                                            v-model="form.return_date_d"
                                                            style="width: 47px; padding-right: 0 !important"
                                                            class="form-control mx-2"
                                                            :class="{
                                                                'is-invalid': submitted && $v.form.return_date.$error
                                                            }"
                                                        />
                                                        日
                                                        <button class="btn-sm btn btn-info mx-2 box-date" type="button">
                                                            カレンダー
                                                            <date-picker
                                                                ref="scheduled_arrived_at"
                                                                v-model="form.return_date"
                                                                :value-type="customFormatDate"
                                                                :first-day-of-week="1"
                                                                lang="ja"
                                                            ></date-picker>
                                                        </button>
                                                    </div>

                                                    <span
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.return_date.$error
                                                        }"
                                                    ></span>
                                                    <div v-if="submitted && $v.form.return_date.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.return_date.required">戻り日を選択してください。</span>
                                                        <span v-if="!$v.form.return_date.validate">{{
                                                            $t('validateField.incorrectFormat', {
                                                                field: '戻り日'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">都道府県名</label>
                                                <div class="col-sm-9">
                                                    <input
                                                        type="text"
                                                        v-model="form.province"
                                                        placeholder=""
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.province.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.province.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.province.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '都道府県'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">配送業者<span class="text-danger">*</span></label>
                                                <div class="col-sm-9">
                                                    <input
                                                        type="text"
                                                        v-model="form.shipping_company"
                                                        placeholder=""
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.shipping_company.$error
                                                        }"
                                                    />

                                                    <div v-if="submitted && $v.form.shipping_company.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.shipping_company.required">{{
                                                            $t('validateField.required', {
                                                                field: '配送業者'
                                                            })
                                                        }}</span>
                                                        <span v-if="!$v.form.shipping_company.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '配送業者'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">問い合わせNo</label>
                                                <div class="col-sm-9">
                                                    <input
                                                        type="text"
                                                        v-model="form.contact_information_number"
                                                        placeholder=""
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.contact_information_number.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.contact_information_number.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.contact_information_number.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '問い合わせNo'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">戻り理由<span class="text-danger">*</span></label>
                                                <div class="col-sm-9">
                                                    <input
                                                        type="text"
                                                        v-model="form.reason"
                                                        placeholder=""
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.reason.$error
                                                        }"
                                                    />

                                                    <div v-if="submitted && $v.form.reason.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.reason.required">戻り理由を入力してください。</span>
                                                        <span v-if="!$v.form.reason.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '戻り理由'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div>
                                                <label for=""><small class="text-gray">ステータス</small></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-10">
                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">終了FLG</label>
                                                <div class="col-sm-9">
                                                    <div class="custom-control custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            v-model="form.end_flag"
                                                            class="custom-control-input"
                                                            value="true"
                                                            :id="`__BVID__742`"
                                                        />
                                                        <label class="custom-control-label" :for="`__BVID__742`"><label></label></label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">対応指示<span class="text-danger">*</span></label>
                                                <div class="col-sm-9">
                                                    <div
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.return_status.id.$error
                                                        }"
                                                    >
                                                        <multiselect
                                                            :showPointer="false"
                                                            :showNoResults="false"
                                                            selectedLabel=""
                                                            deselectLabel=""
                                                            placeholder=""
                                                            selectLabel=""
                                                            :showNoOptions="false"
                                                            track-by="id"
                                                            label="value"
                                                            v-model="form.return_status"
                                                            :options="listDataMasterCompare"
                                                        ></multiselect>
                                                        <i
                                                            v-if="form.return_status && form.return_status.id"
                                                            @click="
                                                                $event.stopPropagation();
                                                                form.return_status = {};
                                                            "
                                                            style="
                                                                cursor: pointer;
                                                                position: absolute;
                                                                right: 23px;
                                                                z-index: 9;
                                                                top: 11px;
                                                                background: #fff;
                                                            "
                                                            class="fa fa-window-close"
                                                        ></i>
                                                    </div>
                                                    <div v-if="submitted && $v.form.return_status.id.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.return_status.id.validate">対応指示を選択してください。</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">パルディアより対応指示項目</label>
                                                <div class="col-sm-9">
                                                    <input
                                                        type="text"
                                                        v-model="form.instruction"
                                                        placeholder=""
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.instruction.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.instruction.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.instruction.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: 'パルディアより対応指示項目'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">再確認日程<span class="text-danger">*</span></label>
                                                <div class="col-sm-8">
                                                    <div class="d-flex align-items-center">
                                                        <input
                                                            type="text"
                                                            v-model="form.reconfirmation_schedule_y"
                                                            style="width: 80px; padding-right: 0 !important"
                                                            class="form-control mr-2"
                                                            :class="{
                                                                'is-invalid': submitted && $v.form.reconfirmation_schedule.$error
                                                            }"
                                                        />
                                                        年
                                                        <input
                                                            type="text"
                                                            v-model="form.reconfirmation_schedule_m"
                                                            style="width: 47px; padding-right: 0 !important"
                                                            class="form-control mx-2"
                                                            :class="{
                                                                'is-invalid': submitted && $v.form.reconfirmation_schedule.$error
                                                            }"
                                                        />
                                                        月
                                                        <input
                                                            type="text"
                                                            v-model="form.reconfirmation_schedule_d"
                                                            style="width: 47px; padding-right: 0 !important"
                                                            class="form-control mx-2"
                                                            :class="{
                                                                'is-invalid': submitted && $v.form.reconfirmation_schedule.$error
                                                            }"
                                                        />
                                                        日
                                                        <button class="btn-sm btn btn-info mx-2 box-date" type="button">
                                                            カレンダー
                                                            <date-picker
                                                                ref="scheduled_arrived_at"
                                                                v-model="form.reconfirmation_schedule"
                                                                :value-type="customFormatDate"
                                                                :first-day-of-week="1"
                                                                lang="ja"
                                                            ></date-picker>
                                                        </button>
                                                    </div>

                                                    <span
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.reconfirmation_schedule.$error
                                                        }"
                                                    ></span>
                                                    <div v-if="submitted && $v.form.reconfirmation_schedule.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.reconfirmation_schedule.required">{{
                                                            $t('validateField.required', {
                                                                field: '再確認日程'
                                                            })
                                                        }}</span>
                                                        <span v-if="!$v.form.reconfirmation_schedule.validate">{{
                                                            $t('validateField.incorrectFormat', {
                                                                field: '再確認日程'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">日本物流開発様対応</label>
                                                <div class="col-sm-9">
                                                    <input
                                                        type="text"
                                                        v-model="form.compatiable_with_jp_logistic_development"
                                                        placeholder=""
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.compatiable_with_jp_logistic_development.$error
                                                        }"
                                                    />
                                                    <div
                                                        v-if="submitted && $v.form.compatiable_with_jp_logistic_development.$error"
                                                        class="invalid-feedback"
                                                    >
                                                        <span v-if="!$v.form.compatiable_with_jp_logistic_development.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '日本物流開発様対応'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">保管棚番</label>
                                                <div class="col-sm-9">
                                                    <input
                                                        type="text"
                                                        v-model="form.storage_code"
                                                        placeholder=""
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.storage_code.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.storage_code.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.storage_code.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '保管棚番'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="validationCustom05">備考</label>
                                                <div class="col-sm-9">
                                                    <input
                                                        type="text"
                                                        v-model="form.note"
                                                        placeholder=""
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': submitted && $v.form.note.$error
                                                        }"
                                                    />
                                                    <div v-if="submitted && $v.form.note.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.note.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: '備考'
                                                            })
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light btn-sm mr-3"
                            @click="
                                $router.push({
                                    path: '/prize-return/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            type="submit"
                            class="btn btn-primary ml-3"
                            v-if="checkPermission('return_prize.register') || (checkPermission('return_prize.edit') && $route.query.id)"
                            @click="formSubmit"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
